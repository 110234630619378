import React, { useState, useLayoutEffect } from 'react'

// compoents
import Layout from 'src/components/Layout'
import useWidth from 'src/hooks/useWidth'
import YoutubeVideo from 'src/components/YoutubeVideo'
import PageTitle from 'src/components/PageTitle'

// Style
import { VideoContainer } from './style'

const WIDTH_MD = 768

type VideoProps = {
  videoSlug: string
  image: string
  link: string
  title: string
  slug: string
}

type pageContextProps = {
  video: VideoProps
  videos: VideoProps[]
}

type VideoPageProps = {
  pageContext: pageContextProps
}

const Video = ({ pageContext }: VideoPageProps) => {
  const [isMobile, setIsMobile] = useState(true)
  const windowWidth = useWidth(300)

  const breadcrumbPath = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Aprenda a Investir',
      link: `/aprenda-a-investir`,
    },
    {
      text: pageContext.video.title,
      link: `/aprenda-a-investir/${pageContext.video.slug}`,
    },
  ]

  useLayoutEffect(() => {
    setIsMobile(windowWidth < WIDTH_MD)
  }, [windowWidth])

  return (
    <Layout>
      <div className="container">
        <div className="row mb-5">
          <PageTitle breadcrumb={{ path: breadcrumbPath }} pageTitle="Aprenda a Investir" />
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <h3 className="fs-20 lh-25 mb-4">{pageContext.video.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <VideoContainer>
              <YoutubeVideo
                src={pageContext.video.link}
                width={isMobile ? '311' : '840'}
                height={isMobile ? '171' : '462'}
                title={pageContext.video.title}
              />
            </VideoContainer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Video
